import React, { useState } from "react";
import initializeFirebase from '../../../firebaseConfig';

import { increment, push, ref, update } from "firebase/database";
import { getCurrentUTCTimestamp } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const ReplyField = ({setIsReplyField,childReplyFieldHandler,type,data,className , projectInfo}) => {

  const firebaseDB = initializeFirebase(projectInfo);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);  
  const [replyCommentValue,setReplyCommentValue] = useState("")

    const replyCommentHandler = () => {
      if(replyCommentValue){
        const firebaseDB = initializeFirebase(projectInfo);
        const dbRefReplies = ref(firebaseDB,`comments/${data?.videoId}/${data?.id}`)
        update(dbRefReplies, {
          reply_count: increment(1),
        });
          push(ref(firebaseDB, `comments/${data?.videoId}/${data?.id}/replies`), {
            comment_text: replyCommentValue,
            user_id: Number(user),
            created_at: getCurrentUTCTimestamp(),
          }).then(()=>setIsReplyField(false))
      }else{
        toast.error("Reply Field is required!",{
          position:"top-center"
        })
      }
    }

    const cancelReplyHandler = () => {
      setIsReplyField(false)
      childReplyFieldHandler(false)
    }
  return (
    <div className={`replyField ${className}`}>
      <div className="left">
        <div className="imageContainer">
          
        {
              userDetails?.user_image?(
                <img src={userDetails?.user_image} alt="Profile" />
              ):(
                <span className="nameAvatar">{userDetails?.first_name?.slice(0, 1)?.toUpperCase()}</span>

              )
            }
        </div>
      </div>
      <div className="right">
        <div className="inputContainer">
          <textarea
            name="comment"
            id="comment"
            value={replyCommentValue || ""}
            onChange={(e) => setReplyCommentValue(e.target.value)}
            cols="30"
            rows="10"
            placeholder="Add your comment"
          ></textarea>
          <div className="actions">
           
            <div className="buttonContainer">
                <button className="cancel" onClick={cancelReplyHandler}> 
                    cancel
                </button>
              <button className="submit" onClick={replyCommentHandler}>
              Leave Reply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyField;
