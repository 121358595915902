import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import CommonPage from "../Components/CommonPage/CommonPage";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeaderAccountsPage from "../Components/Header/HeaderAccountsPage";
import About from "../Screens/About/About";
import AccountSettings from "../Screens/Accounts/AccountSettings/AccountSetting";
import BillingDetails from "../Screens/Accounts/AccountSettings/BillingDetails";
import StreamingActivity from "../Screens/Accounts/AccountSettings/StreamingActivity";
import ForgotPassword from "../Screens/Accounts/ForgotPassword/ForgotPassword";
import ResetPassword from "../Screens/Accounts/ForgotPassword/ResetPassword";
import Login from "../Screens/Accounts/Login/Login";
import Register from "../Screens/Accounts/Register/Register";
import ContactSupport from "../Screens/ContactPages/ContactSupport";
import ContactUs from "../Screens/ContactPages/ContactUs";
import Homepage from "../Screens/homepage/Homepage";
import LandingScreeen from "../Screens/LandingScreen/LandingScreeen";
import MyList from "../Screens/MyList/MyList";
import LatestNews from "../Screens/NewsSection/LatestNews";
import NewsDetails from "../Screens/NewsSection/NewsDetails";
import NotFound from "../Screens/NotFound/NotFound";
import GeoBlock from "../Screens/NotFound/GeoBlock";
import PaymentFailed from "../Screens/PaymentStatus/PaymentFailed/PaymetFailed";
import PaymentSuccess from "../Screens/PaymentStatus/PaymentSuccess/PaymentSuccess";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";
import Sample from "../Screens/Sample";
import Search from "../Screens/Search/Search";
import DetailsScreen from "../Screens/ShowDetailsScreen/DetailsScreen";
import Payment from "../Screens/Subscription/Payment";
import Ideabiz from "../Screens/Subscription/Ideabiz";
import Subscription from "../Screens/Subscription/Subscription";
import TermsAndConditions from "../Screens/TermsAndConditions/TermsAndConditions";
import Videoplayer from "../Screens/VideoPlayer/Videoplayer";
import PrivateRoutes from "./PrivateRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import LinkTvApp from "../Screens/LinkTvApp/LinkTvApp";
import WebViewFailed from "../Screens/PaymentStatus/PaymentFailed/WebViewFailed";
import WebViewSuccess from "../Screens/PaymentStatus/PaymentSuccess/WebViewSuccess";
import PodcastModal from "../Components/Modals/PodcastModal";
import { affliateAnalytics, checkOperatingSystem } from "../utils/utils";
import AffliatePage from "../Screens/AffliatePage/AffliatePage";
import OpenApp from "../Components/OpenApp/OpenApp";
import LiveScriptCheck from "../Screens/LiveScriptCheck";
import Faqs from "../Screens/Faqs/FaqsPremium";
import FaqsPremium from "../Screens/Faqs/FaqsPremium";
import FaqsBasic from "../Screens/Faqs/FaqsBasic";
import TVAppIndex from "../Screens/LinkTvApp/TVAppIndex";

import ActivateIndex from "../Screens/LinkTvApp/ActivateIndex";
import LanguageModal from "../Components/Modals/LanguageModal";
import PaymentProcessing from "../Screens/Subscription/PaymentProcessing";
import LoginRequest from "../Screens/LoginRequest/LoginRequest";
import Videoplayer2 from "../Screens/Videoplayer_and_Comments/Videoplayer2";
import LiveChannels from "../Screens/LiveChannels/LiveChannels";
import CinemaVault from "../Screens/CinemaVault/CinemaVault"
import SubscriptionPlan from "../Screens/SubscriptionPlan/SubscriptionPlan";
import HelpCenter from "../Screens/HelpCenter/HelpCenter";
import SubscriptionPlanDetails from "../Components/SubscriptionPlanDetails/SubscriptionPlanDetails";
import CeyFlixSubcriptionPage from "../Screens/CeyFlixSubscriptionPage/CeyFlixSubcriptionPage";
import CeyFlixHelpCenterPage from "../Screens/CeyFlixHelpCenterPage/CeyFlixHelpCenterPage";
import Checkout from "../Screens/Accounts/Checkout/Checkout";

const LayoutsRoutes = () => {
  const location = useLocation();
  const [initialPage, setInitialPage] = useState("");
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const podcastModal = useSelector((state) => state?.podcastModal?.value);
  const languageModal = useSelector(
    (state) => state?.languageModal?.value
  );
  const urlParams = new URLSearchParams(window.location.search);
  const affliateId = localStorage?.getItem("affliateId");
  const navigate = useNavigate();
  const [isOpenAppFeature, setIsOpenAppFeature] = useState(false);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
  const androidToken = urlParams.get("antkn");
  const isWebView = urlParams.get("web_view");
  const landingPageComponentMapping = {
    CinemaVault,
  };
  const DynamicComponent = landingPageComponentMapping[projectInfo?.projectConfig?.config?.WEBSITE_LANDING_PAGE_COMPONENT] || LandingScreeen;
  const appInfo = {
    projectDetails: projectInfo,
  };
  useEffect(() => {
    if (urlParams.has('d') && urlParams.get('d') === 'm') {
      setHideHeaderFooter(true);
    } else {
      setHideHeaderFooter(false);
    }
  }, [urlParams]); 
  useEffect(() => {
    if (!androidToken) {
      let openAppFeatureNotNeededRoutes = ["/privacy-policy", "/terms-and-conditions", "/subscription", "/payment", "/ideabiz", "success", "/failed", "/404", "/checkout"]

      if (
        (checkOperatingSystem() === "android" ||
          checkOperatingSystem() === "iPhone") &&
        !openAppFeatureNotNeededRoutes?.includes(location?.pathname)

      ) {
        setIsOpenAppFeature(true);
      } else {
        setIsOpenAppFeature(false);
      }
    }
  }, [androidToken]);
  useEffect(() => {
    if (affliateId) {
      if (checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone") {
        navigate("/");
      } else {
        setInitialPage(projectInfo?.projectConfig?.config?.INITIAL_PAGE);
        updateAffliateAnalytics()
      }
    } else {
      setInitialPage(projectInfo?.projectConfig?.config?.INITIAL_PAGE);
    }
  }, [affliateId]);

  const updateAffliateAnalytics = async () => {
    try {
      const response = await affliateAnalytics(appInfo);
      if (response.status === 200) {
        localStorage.removeItem("affliateId");
        localStorage.removeItem("affliateUrl");
        navigate(location?.pathname)

      }
    } catch (err) {
      localStorage.removeItem("affliateId");
      localStorage.removeItem("affliateUrl");
    }
  }

  if (affliateId && (checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone")) {
    return (
      <Routes>
        <Route path="/">
          <Route path="/" element={<Navigate to="/redirect" />} />
        </Route>
        <Route path="/redirect" element={<AffliatePage />} />
      </Routes>
    );
  } else {
    return (
      initialPage && (
        <div className="LayoutsContainer" >
          {location?.pathname !== "/login" &&
            location?.pathname !== "/register" &&
            location?.pathname !== "/forgot-password" &&
            !location?.pathname?.includes("/forgot-password/reset") &&
            location?.pathname !== "/player" &&
            !hideHeaderFooter && !androidToken && <Header />}

          {
            location?.pathname === "/login" |
              location?.pathname === "/register" |
              location?.pathname === "/forgot-password" |
              location?.pathname?.includes("/forgot-password/reset") ? (
              <HeaderAccountsPage />

            ) : null
          }
          {podcastModal?.isOpen && <PodcastModal data={podcastModal?.data} />}
          {languageModal?.isOpen && <LanguageModal />}

          {isOpenAppFeature && (
            <OpenApp setIsOpenAppFeature={setIsOpenAppFeature} />
          )}
          <div className="routesContainer" >
            {
              projectInfo?.projectConfig?.config?.GEO_BLOCKED === true &&
                !projectInfo?.projectConfig?.config?.GEO_UNBLOCKED_COUNTRIES.includes(projectInfo?.geoInfo.countryCode) ? (
                <GeoBlock />
              ) :
                (<Routes>
                  {/* for which page need to show initial */}
                  <Route path="/">
                    {initialPage === "LANDING" ? (
                      <Route path="/" element={<DynamicComponent />} />
                    ) : initialPage === "LOGIN" ? (
                      <Route path="/" element={<Navigate to="/login" />} />
                    ) : initialPage === "HOME" ? (
                      <Route path="/" element={<Navigate to="/home" />} />
                    ) : null}
                  </Route>

                  <Route element={<PublicRoutes />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    
                  </Route>

                  <Route path="/loginRequest" element={<LoginRequest />} />

                  <Route element={<PrivateRoutes />}>
                    <Route path="/home" element={<Homepage />} />
                    <Route path="/show-details/:showId" element={<Homepage />} />
                    <Route path="/event/:eventId" element={<Homepage />} />
                    <Route path="/category/:categoryname" element={<CommonPage />} />
                    <Route path="/search" element={<Search />} />
                    <Route
                      path="/forgot-password/reset/:token"
                      element={<ResetPassword />}
                    />
                    <Route path="/language-selection" element={<LanguageModal type="root" />} />

                    <Route
                      path="/terms-and-conditions"
                      element={<TermsAndConditions />}
                    />
                    {/* <Route
                path="/livescript"
                element={<LiveScriptCheck />}
              /> */}
                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/redirect" element={<Navigate to="/home" />} />
                    <Route path="/homeSub" element={<Navigate to="/home" />} />
                    <Route path="/policyDay" element={<Navigate to="/privacy-policy" />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/contact-support" element={<ContactSupport />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/plans" element={<CeyFlixSubcriptionPage />} />
                    <Route path="/help-center" element={<CeyFlixHelpCenterPage />} />
                    <Route path="/news" element={<LatestNews />} />
                    <Route path="/news-info/:newsId" element={<NewsDetails />} />
                    <Route path="/live-channels" element={<LiveChannels />} />

                    <Route path="/player" element={<Videoplayer />} />

                    <Route path="/videos/:vanityUrl" element={<Videoplayer2 />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/sample" element={<Sample />} />
                    {
                      projectInfo?.projectConfig?.pubid === 50041 &&
                      <>
                        <Route path="/page/FAQ-MMATV-Premium-Plan" element={<FaqsPremium />} />
                        <Route path="/page/FAQ-MMATV-Basic-Plan" element={<FaqsBasic />} />
                      </>
                    }

                  </Route>

                  <Route element={<ProtectedRoutes />}>
                    <Route path="/failed" element={<PaymentFailed />} />
                    <Route path="/success" element={<PaymentSuccess />} />
                    <Route path="/webviewfailed" element={<WebViewFailed />} />
                    <Route path="/webviewsuccess" element={<WebViewSuccess />} />
                    <Route path="/settings" element={<AccountSettings />} />
                    <Route
                      path="settings/billing-activity"
                      element={<BillingDetails />}
                    />
                    <Route
                      path="account/streaming-activity"
                      element={<StreamingActivity />}
                    />
                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/ideabiz" element={<Ideabiz />} />
                    <Route path="/cinetpayReturn" element={<PaymentProcessing />} />
                    <Route path="/my-list" element={<MyList />} />
                    <Route path="/tv" element={<TVAppIndex />} />
                    <Route path="/activate" element={<ActivateIndex />} />

                  </Route>

                  <Route path="*" element={<Navigate to="/404" />}></Route>
                  <Route path="/404" element={<NotFound />} />
                </Routes>
                )}
          </div>
          {
            location?.pathname !== "/player" &&  !hideHeaderFooter && !androidToken && 
            <Footer style={{ flexShrink: 0 }} />
          }
        </div>
      )
    );
  }

};

export default LayoutsRoutes;
