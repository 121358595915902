import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as MenuIcon } from "../../assets/Icons/MenuIcon.svg";
import { ReactComponent as DownArrow } from "../../assets/Icons/filledArrow.svg";
import { ReactComponent as CircleArrow } from "../../assets/Icons/filledCircleArrow.svg";
import { ReactComponent as SignOutIcon } from "../../assets/Icons/signOut.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import {
  appInfo,
  authenticate,
  getUserSubscription,
} from "../../network/service";
import * as service from "./service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import SmallLoading from "../Loading/SmallLoading";
import DetailScreenModal from "../../Screens/ShowDetailsScreen/DetailScreenModal";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { imageUrlCheck } from "../../utils/utils";
import Translate from "../MultiLanguage/Translate"
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import { getEventMoreInfo } from "../../Redux/MoreInfo/eventMoreInfoSlice";

const Header = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const isModalOpen = useSelector((state) => state?.moreInfoOther?.value);

  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [menuItems, setMenuItems] = useState();
  const [browseHover, setBrowseHover] = useState(false);
  const [browseMoreMediumDevice, setBrowseMoreMediumDevice] = useState(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [previousLocation, setPreviousLocation] = useState("");
  const [menuHandler, setMenuHandler] = useState(false);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [IsSearchLoading, setIsSearchLoading] = useState(false);
  const [subscriptionLink, setSubscriptionLink] = useState(true)
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const menuRef = useRef(null);
  const searchRefContainer = useRef(null);
  const searchRefSmall = useRef(null);
  const searchRefSmallContainer = useRef(null);
  const searchSuggestionRef = useRef(null);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      // setHeaderVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (searchActive) {
      // searchRef?.current.focus();
      searchRefSmall?.current?.focus();
      searchRefSmallContainer?.current?.focus();
      setPreviousLocation(location);
    }
  }, [searchActive]);

  useEffect(() => {
    if (accessToken) {
      fetchUserSubscriptionDetails();
    }
  }, [user, accessToken]);

  useEffect(() => {
    fetchMenuItems();
  }, []);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !searchRefContainer?.current?.contains(e.target) &&
        !searchRefSmallContainer?.current?.contains(e.target)
      ) {
        setInputValue("");
        setSearchActive(false); //outside click
      }

      if (!searchSuggestionRef?.current?.contains(e.target)) {
        setInputValue("");
        // setSearchActive(false)
        setSearchResults(null);
      }

      // if (!menuRef?.current?.contains(e.target)) {
      //   setMenuHandler(false); //outside click
      // }
    };

    window.addEventListener("click", handleOutClick);
    return () => {
      window.addEventListener("click", handleOutClick);
    };
  }, [
    searchRefContainer,
    searchRefSmallContainer,
    menuRef,
    searchSuggestionRef,
  ]);

  useEffect(() => {
    if (inputValue) {
      setIsSearchLoading(true);
      fetchSearchData();
    } else {
      setIsSearchLoading(false);
      setSearchResults(null);
    }
  }, [inputValue]);
  // useEffect(() => {
  //   searchResultsShow();
  // }, [searchResults]);
  const fetchMenuItems = async () => {
    try {
      const menuResponse = await service.getMenuItems(appInfo);
      if (menuResponse?.status === 200) {
        setMenuItems(menuResponse?.data?.data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {

      const currentUserSubscription = userSubResponse?.data?.data.filter(
        (item) => item.subscription_type_id === 3 || item.subscription_type_id === 4
      );

      if (currentUserSubscription?.length > 0) {
        setSubscriptionLink(false)
      }

      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  /// Search functionality  start
  const searchSubmit = (value) => {
    if (value) {
      setInputValue("");
      setSearchActive(false);
      if (searchResults?.length > 0) {
        navigate(`/search?query=${value}`, { state: { searchResults } });
      } else {
        navigate(`/search?query=${value}`);
      }
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
  };
  const searchSubmitBrowseMore = (value) => {
    setSearchActive(true);
    if (value) {
      setInputValue("");
      if (searchResults?.length > 0) {
        navigate(`/search?query=${value}`, { state: { searchResults } });
      } else {
        navigate(`/search?query=${value}`);
      }
      setBrowseMoreMediumDevice(null);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
    // if (!searchActive) {
    //   setSearchActive(true);
    // } else {
    //   setSearchActive(false);
    //   setBrowseMoreMediumDevice(null);
    // }
  };

  /// end

  const logoutHandler = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedSubId");
    localStorage.removeItem("deviceType");
    localStorage.removeItem("redirectPath");
    tokenAuthenticate();
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      navigate("/home");
      setTimeout(() => {
        dispatch(
          getUser({
            user: null,
          })
        );
      }, 300);
    }
  };

  const fetchSearchData = async () => {
    try {
      const response = await service?.getSearchData(appInfo, inputValue);
      if (response?.status === 200) {
        setSearchResults(response?.data?.data);
        setIsSearchLoading(false)
      } else {
      }
    } catch (err) {
      setIsSearchLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const showClickHandler = (data, medium) => {
    if (medium) {
      setBrowseMoreMediumDevice(false);
    }
    if (data?.type === "LIVE_EVENT") {
      navigate(`/event/${data?.show_id}`, { state: { data } })
      dispatch(
        getEventMoreInfo({
          eventMoreInfo: {
            isModal: true,
            eventId: data?.vanity_url ? data?.vanity_url : data?.event_id
          }
        })
      )
    } else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal: podcastData
        })
      )
    } else if (data?.type === "NEWS") {
      navigate(`/news-info/${data?.show_id}`)
    }
    else if (data?.type === "CONTINUE_WATCHING") {
      navigate(`/show-details/${data?.vanity_url ? data?.vanity_url : data?.show_id}`, {
        state: { showId: data?.show_id, type: data?.type },
      })
    }
    else if (data?.type === "UPCOMING_EVENT") {
      dispatch(
        getEventMoreInfo({
          eventMoreInfo: {
            isModal: true,
            eventId: data?.vanity_url ? data?.vanity_url : data?.event_id
          }
        })
      )
      navigate(`/event/${data?.show_id}`, { state: { data } })

    }
    else if (data?.type === "VIDEO") {
      navigate(`/videos/${data?.vanity_url ? data?.vanity_url : data?.show_id}`, {
        state: { videoDetails: data },
      })
    }
    else if (data?.type === "SHOW") {
      navigate(`/show-details/${data?.vanity_url ? data?.vanity_url : data?.show_id}`, {
        state: { showId: data?.show_id, type: data?.type },
      })
    }
    else if (data?.type === "FASTCHANNEL") {
      navigate(`/live-channels`, {
      })
    }
    else if (data?.type === "EVENT") {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`)
    }
    else if (data?.type === "PODCASTS") {
      let podcastData = {
        isOpen: true,
        data
      }
      dispatch(
        getPodcastModal({
          podcastModal: podcastData
        })
      )
    }
    else {
      navigate(`/show-details/${data?.vanity_url}`, {
        state: { showId: data?.show_id, type: data?.type },
      })
      dispatch(
        getMoreInfo({
          moreInfo: true
        })
      )
      dispatch(
        getMoreInfoOther({
          moreInfoOther: {
            isModal: true,
            showId: data?.vanity_url ? data?.vanity_url : data?.show_id,
            isFromSearch: true
          }
        })
      )
      setSearchResults(null);
    }
  };

  const searchClose = () => {
    setSearchActive(false);
    setInputValue("");
    setSearchResults(null);
  };
  return (
    <>
      {
        isModalOpen?.isModal && isModalOpen?.isFromSearch &&
        <DetailScreenModal />
      }
      <ToastContainer />
      <div
        className={browseMoreMediumDevice ? "active overlayOne" : "overlayOne"}
        onClick={() => setBrowseMoreMediumDevice(false)}
      ></div>
      <div
        className={
          position === 0 ? "header-container" : "header-container background"
        }
      >
        <div className="wrapper">
          <div className="menuIconContainer">
            <MenuIcon
              ref={menuRef}
              onClick={() => setBrowseMoreMediumDevice(true)}
            />
          </div>
          <div className="leftContainer">
            <div className="logoContainer">
              <Link to="/home">
                <h1>
                  <img
                    src={projectInfo?.projectConfig?.config?.LOGO}
                    alt="Logo"
                  />
                </h1>
              </Link>
            </div>

            <nav>
              <ul className="navLinks">
                <Link to="/home">
                  <li className="link"><Translate textKey={'home'} /></li>
                </Link>
                {projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" ? (<Link to="/live-channels" >
                  <li className="link"><Translate textKey={'live'} /></li>
                </Link>) : (projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" && <Link to="/player" state={{ isLive: true, prevLocation: location?.search ? location?.pathname + location?.search : location?.pathname }}>
                  <li className="link"><Translate textKey={'live'} /></li>
                </Link>)}
                {
                  user &&
                  <Link to="/my-list">
                    <li className="link"><Translate textKey={'my_list'} /></li>
                  </Link>
                }
                <li
                  className={browseHover ? " link active" : "link no-active"}
                  onClick={() => {
                    setBrowseHover(!browseHover);
                    setBrowseMoreMediumDevice(true);
                    setMenuHandler(false);
                  }}
                >
                  <Translate textKey={'browse'} />
                </li>
                {subscriptionLink && projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' && <Link to="/checkout">
                  <li className="link">{projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT || 'Subscribe'}</li>
                </Link>}
              </ul>
            </nav>
          </div>
          <div className="rightContainer">
            <div
              className={
                searchActive ? "inputContainer showInput" : "inputContainer"
              }
              ref={searchRefContainer}
              onClick={() => {
                setSearchActive(true);
                searchRef?.current.focus();
              }}
            >
              {searchActive ? (
                <SearchIcon
                  onClick={
                    () => {
                      searchSubmit(inputValue);
                      searchRef?.current.focus();
                    }
                    // setSearchActive(!searchActive)
                  }
                />
              ) : (
                <SearchIcon
                  onClick={() => {
                    searchRef?.current.focus();
                    setSearchActive(true);
                  }}
                />
              )}

              <input
                ref={searchRef}
                onClick={() => setSearchActive(true)}
                type="text"
                value={inputValue || ""}
                // placeholder={searchActive && "Find Movies, TV shows and more"}
                placeholder="Search"
                onChange={(e) => {
                  // searchHandleChange(e?.target?.value);
                  setInputValue(e?.target?.value);
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? searchSubmit(inputValue) : ""
                }
              />
              {IsSearchLoading && (
                <div className="searchLoading">
                  <SmallLoading />
                </div>
              )}

              {!IsSearchLoading && searchResults?.length > 0 && inputValue && (
                <div className="closeIcon">
                  <CloseIcon onClick={() => searchClose()} />
                </div>
              )}
              {searchResults?.length > 0 && inputValue && (
                <div className="searchSuggestionsContainer" ref={searchSuggestionRef}>
                  <ul className="lists">
                    {searchResults?.slice(0, 8)?.map((item, index) => (
                      <div key={index} className="categorySection">
                        <h6 className="heading">
                          {item?.category_name}
                        </h6>
                        {item?.shows?.map((show, showIndex) => (
                          <li
                            className="list"
                            key={`${index}-${showIndex}`}
                            onClick={() => showClickHandler(show)}
                          >
                            <div className="listContainer">

                              <div className="left">
                                <div className="imageContainer">
                                  {imageUrlCheck(
                                    thumbnailOrientation === "PORTRAIT"
                                      ? show?.logo
                                      : show?.logo_thumb
                                  ) ? (
                                    <img
                                      src={
                                        thumbnailOrientation === "PORTRAIT"
                                          ? `${show?.logo}`
                                          : `${show?.logo_thumb}`
                                      }
                                      alt="Thumb"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        thumbnailOrientation === "PORTRAIT"
                                          ? `${process.env.REACT_APP_IMAGE_URL}${show?.logo}`
                                          : `${process.env.REACT_APP_IMAGE_URL}${show?.logo_thumb}`
                                      }
                                      alt="Thumb"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="right">
                                <h1 className="title">
                                  {show?.show_name?.slice(0, 50)}
                                  {show?.show_name?.length > 50 && "..."}
                                </h1>
                                <span className="director">{show?.director}</span>
                              </div>

                            </div>
                          </li>
                        ))}
                      </div>
                    ))}
                  </ul>
                  <div className="seeAll" onClick={() => searchSubmit(inputValue)}>
                    <button>
                      <Translate textKey={'see_all'} />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className={menuHandler ? "userIcon" : "userIcon noActive"} onMouseEnter={() => setMenuHandler(true)} onClick={() => setMenuHandler(!menuHandler)}>
              <div className="imageContainer">
                <img
                  src={require("../../assets/Images/avatar.png")}
                  alt="Avatar"
                />
              </div>
              <div className="downArrow">
                <DownArrow />
              </div>
              <div className={menuHandler ? "menuListContainer" : "noActive menuListContainer"}>
                <div className="arrow">
                  <DownArrow />
                </div>
                <ul className="list">
                  {/* <li onClick={() => navigate("/privacy-policy")}>
                    <div className="icon">
                      <img
                        src={require("../../assets/Icons/privacy-policy.png")}
                        alt="Icon"
                      ></img>
                    </div>
                    <span>Privacy Policy</span>
                  </li>
                  <li onClick={() => navigate("/terms-and-conditions")}>
                    <div className="icon">
                      <img
                        src={require("../../assets/Icons/terms-and-conditions.png")}
                        alt="Icon"
                      ></img>
                    </div>
                    <span>Terms of Use</span>
                  </li>
                  <li onClick={() => navigate("/about-us")}>
                    <div className="icon">
                      <img
                        src={require("../../assets/Icons/info-button.png")}
                        alt="Icon"
                      ></img>
                    </div>
                    <span>About Us</span>
                  </li> */}
                  {user && (
                    <>
                      <Link to="/settings">
                        <li>
                          <div className="icon">
                            <img
                              src={require("../../assets/Icons/user.png")}
                              alt="Icon"
                            ></img>
                          </div>
                          <span><Translate textKey={'account'} /></span>
                        </li>
                      </Link>
                      <Link to="/tv">
                        <li>
                          <div className="icon">
                            <img
                              src={require("../../assets/Icons/television.png")}
                              alt="Icon"
                            ></img>
                          </div>
                          <span><Translate textKey={'link_tv_app'} /></span>
                        </li>
                      </Link>
                    </>
                  )}
                  <Link to="/contact-support">
                    <li>
                      <div className="icon">
                        <img
                          src={require("../../assets/Icons/contact.png")}
                          alt="Icon"
                        ></img>
                      </div>
                      <span><Translate textKey={'contact_support'} /></span>
                    </li>
                  </Link>
                  {user && subscriptionLink && <Link to="/subscription">
                    <li>
                      <div className="icon">
                        <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 2H6v2h12V2zM4 6h16v2H4V6zm-2 4h20v12H2V10zm18 10v-8H4v8h16z" fill="#ffffff"></path> </g></svg>
                      </div>
                      <span>Subscribe Now</span>
                    </li>
                  </Link>}
                  {user ? (
                    <li className="logOut" onClick={() => logoutHandler()}>
                      <span><Translate textKey={'sign_out_of_appname'} /></span>
                    </li>
                  ) : (
                    <li className="logIn" onClick={() => navigate("/login")}>
                      <span>
                        <Translate textKey={'log_in_to_appname'} /></span>
                    </li>
                  )
                  }
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={browseHover ? "browseMore active" : "browseMore"}>
        <div className="overlay" onClick={() => setBrowseHover(null)}></div>

        <div className="browseMoreContainer">
          <div className="arrow">
            <DownArrow />
          </div>
          {menuItems?.map((item, index) => (
            <div className="left" key={index}>
              <h1 className="categoryName">{item?.key}</h1>
              <ul>
                {item?.items?.map((itemShow, keyIndex) => (
                  <li key={keyIndex}>
                    <Link
                      to={`/category/${itemShow?.value}?career=${itemShow?.key}`}
                      state={{ careers: itemShow?.key }}
                      onClick={() => setBrowseHover(null)}
                    >
                      <span> {itemShow?.value}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="browseMoreResponsive">
        <div
          className={
            browseMoreMediumDevice ? "searchArea active" : "searchArea"
          }
        >
          <div className="inputContainer" ref={searchRefSmallContainer}>
            <SearchIcon
              onClick={() => {
                // searchResultsShow();
                searchSubmitBrowseMore(inputValue);
              }}
            />

            <input
              ref={searchRefSmall}
              onClick={() => {
                setSearchActive(true);
              }}
              type="text"
              value={inputValue}
              placeholder={!searchActive && "Search"}
              onChange={(e) => {
                // searchHandleChange(e?.target?.value);
                setInputValue(e?.target?.value);
              }}
              onKeyDown={(e) =>
                e.key === "Enter" ? searchSubmitBrowseMore(inputValue) : ""
              }
            />
          </div>
          {IsSearchLoading && (
            <div className="searchLoading">
              <SmallLoading />
            </div>
          )}

          {!IsSearchLoading && searchResults?.length > 0 && inputValue && (
            <div className="closeIcon">
              <CloseIcon onClick={() => searchClose()} />
            </div>
          )}
          {searchResults?.length > 0 && inputValue && (
            <div
              className="searchSuggestionsContainerMedium"
              ref={searchSuggestionRef}
            >
              <h6 className="heading">
                <Translate textKey={'total'} /> <span className="count">{searchResults?.length}</span>
              </h6>
              <ul className="lists">
                {searchResults?.slice(0, 8)?.map((item, index) => (
                  <li
                    className="list"
                    key={index}
                    onClick={() => showClickHandler(item, true)}
                  >
                    <div className="left">
                      <div className="imageContainer">
                        {
                          imageUrlCheck(thumbnailOrientation === "PORTRAIT" ? item?.logo : item?.logo_thumb) === true ? (
                            <img
                              src={
                                thumbnailOrientation === "PORTRAIT"
                                  ? `${item?.logo}`
                                  : `${item?.logo_thumb}`
                              }
                              alt="Thumb"
                            />
                          ) : (

                            <img
                              src={
                                thumbnailOrientation === "PORTRAIT"
                                  ? `${process.env.REACT_APP_IMAGE_URL}${item?.logo}`
                                  : `${process.env.REACT_APP_IMAGE_URL}${item?.logo_thumb}`
                              }
                              alt="Thumb"
                            />
                          )
                        }
                      </div>
                    </div>

                    <div className="right">
                      <h1 className="title">{item?.show_name?.slice(0, 50)}{item?.show_name?.length > 50 && "..."}</h1>
                      <span className="director">{item?.director}</span>
                    </div>
                  </li>
                ))}
              </ul>
              <div
                className="seeAll"
                onClick={() => searchSubmitBrowseMore(inputValue)}
              >
                <button><Translate textKey={'see_all'} /></button>
              </div>
            </div>
          )}
        </div>
        <div
          className={browseMoreMediumDevice ? "container active" : "container"}
        >
          <div className="head large">
            <div className="left">
              <h1><Translate textKey={'browse'} /></h1>
            </div>
            <div className="right">
              <CloseIcon onClick={() => setBrowseMoreMediumDevice(false)} />
            </div>
          </div>
          <ul className="nav">
            <Link to="/home">
              <li onClick={() => setBrowseMoreMediumDevice(false)}><Translate textKey={'home'} /></li>
            </Link>
            {projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" ? (<Link to="/live-channels" >
              <li onClick={() => setBrowseMoreMediumDevice(false)} className="link"><Translate textKey={'live'} /></li>
            </Link>) : (projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" && <Link to="/player" state={{ isLive: true, prevLocation: location?.search ? location?.pathname + location?.search : location?.pathname }}>
              <li onClick={() => setBrowseMoreMediumDevice(false)} className="link"><Translate textKey={'live'} /></li>
            </Link>)}
            {
              user &&
              <>
                <Link to="/my-list">
                  <li onClick={() => setBrowseMoreMediumDevice(false)}><Translate textKey={'my_list'} /></li>
                </Link>
                <Link to="/tv">
                  <li onClick={() => setBrowseMoreMediumDevice(false)}><Translate textKey={'link_tv_app'} /></li>
                </Link>
              </>
            }
            {subscriptionLink && projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' && <Link to="/checkout">
              <li className="link" onClick={() => setBrowseMoreMediumDevice(false)}>{projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT || 'Subscribe'}</li>
            </Link>}
          </ul>
          {menuItems?.map((item, index) => (
            <div className="top" key={index}>
              <h1 className="categoryName">{item?.key}</h1>
              <ul>
                {item?.items?.map((items, i) => (
                  <li key={i}>
                    <Link
                      to={`/category/${items?.value}?career=${items?.key}`}
                      state={{ careers: items?.key }}
                      onClick={() => setBrowseMoreMediumDevice(false)}
                    >
                      <span> {items?.value}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Header;
