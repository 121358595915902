import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as UserIcon } from "../../../assets/Icons/user_avatar.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/Icons/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/Icons/googleIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import * as service from "./service";
import { getUser } from "../../../Redux/UserSlice/UserSlice";
import { analytics2, authenticate } from "../../../network/service";
import { getAccessToken } from "../../../Redux/AToken/ATokenSlice";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Translate from "../../../Components/MultiLanguage/Translate";
import LoginModal from "../../../Components/Modals/LoginModal/LoginModal";
import { getLanguageModal } from '../../../Redux/LanguageModal/languageModalSlice';
import OtpModal from "../../../Components/Modals/OtpModal";
import SocialAccountLoginModal from "../../../Components/Modals/SocialAccountLoginModal";
import ApplePrivateLoginModal from '../../../Components/Modals/LoginModal/ApplePrivateLoginModal'

const Login = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [emailLoginModal, setEmailLoginModal] = useState(false)
  const [otpModal, setOtpModal] = useState(false);
  const [userRegisterId, setUserRegisterId] = useState();
const [showSocialLoginModal, setShowSocialLoginModal] = useState(false);
  const [socialLoginModalType, setSocialLoginModalType] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  const [redirectUrl, setRedirectUrl] = useState("");
  const location = useLocation();
  const [googleUser, setGoogleUser] = useState();
  const [applePrivateLogin, setApplePrivateLogin] = useState(false)
  const [applePrivateEmail, setApplePrivateEmail] = useState()
  const [applePrivateData, setApplePrivateData] = useState()

  const facebookRef = useRef(null);
  const state = location?.state;
  const tvCode = state?.code;
  const redirectPath = localStorage.getItem("redirectPath");
  useEffect(() => {
    window.scroll(0, 0);
    if (location?.state?.tv) {
      setRedirectUrl(location?.state?.location?.pathname + location?.state?.location?.search)
    } else if (location?.state?.path) {
      setRedirectUrl(location?.state?.path);
    } 
    else if(redirectPath){
      setRedirectUrl(redirectPath);
    }
    else {
      setRedirectUrl("/home");
    }
  }, [location?.state]);

  useEffect(() => {
    if (googleUser) {
      getgoogleUserData();
    }
  }, [googleUser]);

  useEffect(() => {
    if (applePrivateEmail) {
      const appleData = {
        ...applePrivateData,
        ['email']: applePrivateEmail
      }
      appleLoginWithGizmott(appleData)
    }
  }, [applePrivateEmail])

  useEffect(() => {

    if (projectInfo?.projectConfig?.config?.APPLE_LOGIN_REQUIRED == "true") {
      // Load Apple Sign-In script
      const script = document.createElement('script');
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        // Ensure that AppleID is defined on the window object
        if (window.AppleID) {
          // Initialize Apple Sign-In
          window.AppleID.auth.init({
            clientId: projectInfo?.projectConfig?.config?.APPLE_CLIENT_ID,
            scope: 'email name',
            redirectURI: projectInfo?.projectConfig?.config?.APPLE_LOGIN_REDIRECT_URL,
            usePopup: true,
          });

          // Listen for authorization success
          document.addEventListener('AppleIDSignInOnSuccess', (event) => {
            // Handle successful response.
            const appleData = jwtDecode(event?.detail?.authorization?.id_token);

            if (appleData?.sub) {
              if (appleData?.is_private_email == "true" && appleData?.sub) {
                setApplePrivateData(appleData)
                setApplePrivateLogin(true)
              } else {
                appleLoginWithGizmott(appleData)
              }
            } else {
              // fail silently
              toast.error('Something went wrong, please try again later!')
            }
          });

          // Listen for authorization failures
          document.addEventListener('AppleIDSignInOnFailure', (event) => {
            // Handle error.
            console.error('Apple Sign-in failed:', event.detail.error);
          });
        } else {
          console.error('AppleID is not defined.');
        }
      };

      // Cleanup when the component is unmounted
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);

  const getgoogleUserData = async () => {
    const response = await service.googleLogin(googleUser?.access_token);
    if (response?.status === 200) {
      const values = {
        googleId: response?.data?.id,
        loginType: "google",
        firstName: response?.data?.name,
        email: response?.data?.email,
      };
      loginWithoutCode(values);
    }
  };
  const appleLoginWithGizmott = (data) => {

    console.log('Apple Sign-in successful:', data);
    const { email, sub } = data
    const name = email?.split('@')[0];
    const appleUserDetails = {
      appleId: sub,
      loginType: "apple",
      firstName: name,
      email,
    };
    loginWithoutCode(appleUserDetails);
  }

  const loginWithoutCode = async (values) => {
    const response = await service.updateLoginWithoutCode(appInfo, values);
    if (response?.status === 200) {
      localStorage.setItem("userId", response?.data?.data[0]?.user_id);
      tokenAuthenticate();
      updateDeviceAnalytics(
        response?.data?.data[0]?.user_id,
        appInfo,
        response?.data?.data[0]?.user_email
      );
      dispatch(
        getUser({
          user: response?.data?.data[0]?.user_id,
        })
      );
      navigate(redirectUrl, { state: { ...location?.state } });     
    }
    else if(response?.status === 204)
    {
        setSocialLoginModalType({
          heading:`Already registered with the same email. Do you want to link your ${values?.loginType} account?`,
          values:values
        });
        setShowSocialLoginModal(true);
    }

  };
  const googleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const responseFacebook = (response) => {
    if (response?.userID) {
      const values = {
        facebookId: response?.userID,
        loginType: "facebook",
        firstName: response?.name,
        email: response?.email,
      };
      loginWithoutCode(values)
    }
    // You can handle the Facebook login response here
  };
  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };
  const handleChange = (name, value) => {
   
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validateLogin = () => {
    let error = {};
    let validateStatus = true;
    const { email, password } = values;
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if (!email) {
      error.email = <Translate textKey={'email_required_message'}/>;
      validateStatus = false;
    } else if (!email?.match(emailRegex)) {
      error.email = <Translate textKey={'valid_email_message'}/>;
      validateStatus = false;
    }
    if (!password) {
      error.password = <Translate textKey={'password_required'}/>;
      validateStatus = false;
    }
    // else if (!password?.match(passwordRegex)) {
    //   error.password =
    //     "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special characters!";
    //   validateStatus = false;
    // }
    setErrors(error);
    return validateStatus;
  };
  const updateDeviceAnalytics = async (userId, appInfo, userEmail) => {
    await analytics2(userId, appInfo, userEmail);
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      localStorage.setItem('selectedLanguageCode',response?.data?.user_language?.short_code);
      localStorage.setItem('selectedLanguageId',response?.data?.user_language?.language_id);
      dispatch(
        getLanguageModal({
          languageModal: {
            selectedLanguageCode: response?.data?.user_language?.short_code
          }
        }) )
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      dispatch(
        getUserDetails({
          userDetails: response?.data,
        })
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const validateStatus = validateLogin();
      const loginResponse = await service.login(values, appInfo);
      if (validateStatus) {
        if (loginResponse?.status === 200) {
          localStorage.setItem("userId", loginResponse?.data?.data[0]?.user_id);
          tokenAuthenticate();
          updateDeviceAnalytics(
            loginResponse?.data?.data[0]?.user_id,
            appInfo,
            loginResponse?.data?.data[0]?.user_email
          );
          dispatch(
            getUser({
              user: loginResponse?.data?.data[0]?.user_id,
            })
          );
         
          if (location?.state?.tv) {
            navigate(redirectUrl,  { state: { ...location?.state, path: redirectUrl, replace: true  } });          
          } else {
            navigate(redirectUrl, { state: location?.state, replace: true });
          }
        }
        else if (
          loginResponse?.status === 201 &&
          appInfo?.projectDetails?.projectConfig?.config
            ?.REGISTRATION_OTP_REQUIRED === "true"
        ) {
          toast.success(loginResponse?.data?.message, {
            position: "top-center",
          });
          setOtpModal(true);
          setUserRegisterId(loginResponse?.data?.data[0]?.user_id);
        } else {
          toast.error(loginResponse?.data?.message, {
            position: "top-center",
          });
        }
      } else {
        return false;
      }
    } catch (err) {
      toast.error(err?.loginResponse?.data?.message, {
        position: "top-center",
      });
    }
  };

  return (
    <div className="loginPage">
      <ToastContainer />
      {otpModal && (
        <OtpModal
          setOtpModal={setOtpModal}
          appInfo={appInfo}
          userRegisterId={userRegisterId}
          updateDeviceAnalytics={updateDeviceAnalytics}
          tokenAuthenticate={tokenAuthenticate}
          values={values}
          urlToRedirect={redirectUrl}
        />
      )}

      {
        emailLoginModal &&
        <LoginModal isEmailForm={true} setEmailLoginModal={setEmailLoginModal} tvCode={tvCode} />
      }
       {showSocialLoginModal && (
        <SocialAccountLoginModal
          modalType={socialLoginModalType}
          setShowSocialLoginModal={setShowSocialLoginModal}
        />
      )}
      {/* {
          projectInfo?.projectConfig?.pubid === 50040 &&
          <div className="updatesContainer">
            <p><Translate textKey={'login_page_mesage'}/></p>
          </div>
        } */}

      {
        applePrivateLogin &&
        <ApplePrivateLoginModal isEmailForm={true} setApplePrivateLogin={setApplePrivateLogin} setApplePrivateEmail={setApplePrivateEmail} />
      } 
      
      {
        projectInfo?.projectConfig?.config?.CUSTOM_LOGIN_SCREEN_MESSAGE &&
        <div className="updatesContainer">
          <p> {projectInfo?.projectConfig?.config?.CUSTOM_LOGIN_SCREEN_MESSAGE} </p>
        </div>
      }

      <div className="loginContainer">

        <div className="top">
          <div className="userIconContainer">
            <UserIcon />
          </div>
          <h1><Translate textKey={'sign_in_title'} /></h1>
          {projectInfo?.projectConfig?.config?.SOCIAL_LOGIN_REQUIRED ===
            "true" &&

            (
            <>
              {projectInfo?.projectConfig?.config?.APPLE_LOGIN_REQUIRED == "true" &&
                <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in" style={{ padding: '0px', marginBottom: '8px' }}></div>
              }

              {projectInfo?.projectConfig?.config?.FACEBOOK_LOGIN_REQUIRED ===
                "true" && (
                <FacebookLogin
                  appId={projectInfo?.projectConfig?.config?.FACEBOOK_APP_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  ref={facebookRef}
                  render={(renderProps) => (
                    <div className="facebookContainer" onClick={renderProps.onClick}>
                      <div className="background"></div>
                      <div className="contents">
                        <div className="left">
                          <FacebookIcon />
                        </div>
                        <div className="right">
                          <span><Translate textKey={'sign_in_via_facebook'} /></span>
                        </div>
                      </div>
                    </div>
                  )}
                  onFailure={(res)=>console.log(res,"res")}
                />
              )}

              {projectInfo?.projectConfig?.config?.GOOGLE_LOGIN_REQUIRED ===
                "true" &&
                projectInfo?.projectConfig?.config?.GOOGLE_CLIENT_ID&&
                (
                <div
                  className="googleContainer"
                  onClick={() => {
                    googleLoginHandler();
                  }}
                >
                  <div className="background"></div>
                  <div className="contents">
                    <div className="left">
                      <GoogleIcon />
                    </div>
                    <div className="right">
                      <span><Translate  textKey={'google_sign_in'}/></span>
                    </div>
                  </div>
                </div>
              )}
		        {projectInfo?.projectConfig?.config?.LOGIN_WITH_MAGIC_LINK_REQUIRED === "true" &&
                  <div className="buttonContainer"
                    onClick={() => {
                      setEmailLoginModal(true);
                    }}
                  >
                    <div className="background"></div>
                    <button><Translate textKey= {'sign_in_via_email'} /></button>
                  </div>
                }

              <div className="seperate">
                <span className="line"></span>
                <span><Translate textKey={'or'} /></span>
                <span className="line"></span>
              </div>
            </>
          )}
        </div>
        <div className="bottom">
          <h3><Translate textKey={'sign_in_via_email_password'}/></h3>
          <form action="post">
            <div className="emailContainer">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors?.email && <span className="error">{errors?.email}</span>}
            <div className="passwordContainer">
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={(item) => {
                  handleUpdate(item);
                }}
              />
            </div>{" "}
            {errors?.password && (
              <span className="error">{errors?.password}</span>
            )}
            <div
              className="buttonContainer"
              onClick={(event) => {
                handleSubmit(event);
              }}
            >
              <div className="background"></div>
              <button><Translate textKey={'sign_in'}/></button>
            </div>
            <h4
              className="forgotPassword"
              onClick={() => navigate("/forgot-password")}
            >
             <Translate textKey={'forgot_password'}/>
            </h4>
            <h4>
             <Translate textKey={'sign_in_register_text'} />{" "}
              <span
                onClick={() =>
                  navigate("/register", {
                    state: { ...location.state, path: location?.state?.path },
                  })
                }
              >
               <Translate textKey={'register'}/>
              </span>
            </h4>
          </form>
          <p>
           <Translate textKey={'register_agree_text'}/> <br />{" "}
            <span onClick={() => navigate("/terms-and-conditions")}>
             <Translate textKey={'terms_of_use'}/>
            </span>{" "}
           <Translate textKey={'and'}/>{" "}
            <span onClick={() => navigate("/privacy-policy")}>
              <Translate textKey={'privacy_policy'}/>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
